.root {
  padding: 10px 0px;
}

.wrap {
  border-radius: 8px;
  border: 1px solid var(--sec-01);
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 10px;
  border-bottom: 1px solid var(--sec-01);
  height: 61px;
}

.title {
  font-family: var(--font-bold);
  font-size: 20px;
}

.replace-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-table thead {
  background-color: var(--gray-xl);
}

.main-table {
  border-collapse: collapse;
  padding: 0px;
  width: 100%;
  min-height: 500px;
}

.main-table th {
  height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.sub-table {
  border-collapse: collapse;
  width: 100%;
}

.highlights-cell {
  width: 60%;
}

.occurrences-table tbody {
  display: block;
  max-height: 600px;
  overflow-y: auto;
}

.occurrences-table thead tr {
  display: block;
}

.occurrences-table tr td:first-child {
  width: 50px;
}

.check-col {
  width: 50px;
}

.occurrences-col {
  width: 100px;
}

.word-col {
  width: auto;
}


.sub-table td, .sub-table th {
  border-spacing: 0px;
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.sub-table th {
  border-bottom: 1px solid var(--gray-l);
}

.table tr:last-child td {
  border-bottom: none;
}

.highlights-cell {
  padding: 0px !important;
  width: 70%;
  vertical-align: top;
  background-color: white;
  border-bottom: 0px !important;
}

/*
.occurrences-cell>div {
  display: flex;
  gap: 10px;
}

.occurrences-cell:hover .view-occurrences {
  visibility: visible;
}
*/

.view-occurrences {
  cursor: pointer;
  text-decoration: underline;
  color: var(--pri-02);
  visibility: hidden;
}

.footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--gray-l);
}

.pagination {
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-btn {
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 12px;
  color: var(--gray-d);
  cursor: pointer;
}

.page-btn:not(.disabled):hover {
  background-color: var(--gray-l);
}

.page-btn.disabled {
  cursor: not-allowed;
  color: var(--gray-l);
}

.term-cell {
  display: flex;
  align-items: center;
  gap: 5px;
}

.typo {
  display: inline-block;
  position: relative;
  background: url(data:image/gif;base64,R0lGODdhBAADAPEAANv///8AAP///wAAACwAAAAABAADAEACBZQjmIAFADs=) bottom repeat-x;
  color: var(--pri-04);
}

.actions {
  justify-self: flex-end;
}

.spacer {
  flex-grow: 1;
}

.main-table>tr>td {
  padding: 0px;
  vertical-align: top;
}

.sub-table thead td {
  padding: 0px;
}
.switch {
  width: 40px;
  height: 20px;
  min-height: 20px;
}

.switch.on {
  background-color: var(--pri-03);
}

.switch.off {
  background-color: var(--gray-l);
}

.toggle.off {
  background-color: var(--gray-m);
  height: 20px;
  width: 20px;
}

.toggle.on {
  background-color: var(--pri-01);
  border: 1px solid var(--sec-01);
  height: 20px;
  width: 20px;
}
.root {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.root {
  width: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
}

.content {
  border: none;
}

@media (min-width: 425px) {
  .content {
    min-height: 50vh;
    height: 50vh;
  }

  .table {
    min-height: inherit;
    height: inherit;
  }
}

.table {
  border-radius: 8px;
  border: 1px solid var(--sec-01);
}

.header {
  padding: 0 15px;
  font-family: var(--font-bold);
}
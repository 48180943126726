.root {

}

.wrap {
  max-width: 600px;
}

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 1000px) {
  .main {
    flex-direction: row;
    align-items: flex-start;
  }
}

.column {

}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 1000px) {
  .item {
    width: 230px;
  }
}

.add {
  composes: item;
  height: 41px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
}

.btn {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.btn:hover {
  background-color: var(--gray-xl);
  color: var(--black-l);
}

.btn:active {
  background-color: var(--blue-xl);
}

.remove button {
  color: var(--gray-l);
  margin: 0 5px;
  transition: color 150ms ease-in-out;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .remove {
    display: none;
  }

  .item:hover .remove {
    display: block;
  }

  .remove:hover button {
    color: var(--gray);
  }
}
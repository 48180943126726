.root {
  height: 40px;
  width: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.landscape {
  /* height: auto; */
  width: 100%;
  max-width: 165px;
}

.responsive {
  display: flex;
  align-items: center;
}

.sm {
  display: none;
}

.lg {
  margin-top: 1px;
  display: block;
}

@media (min-width: 800px) and (max-width: 1200px) {
  .sm {
    display: block;
  }

  .lg {
    display: none;
  }
}

.trinity {
  max-height: 34px;
  margin-top: 0;
}

.loading {
  opacity: 0.3;
}

.horizontal {
  height: auto;
  object-fit: cover;
  /* width: 100px; */
}

.root.horizontal,
.root.horizontal .root {
  max-height: 48px;
  height: auto;
}

.root.trinity,
.root.trinity .root {
  max-height: 34px;
}
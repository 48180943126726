.root {
  width: 100%;
  --sidepanel-width: 260px;
}

.slideout {
  position: absolute;
  top: var(--navbar-height);
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 80%;
  max-width: 800px;
  max-height: 100%;
  background-color: var(--pri-01);
  border-left: 1px solid var(--gray-l);
}

.header {
  position: relative;
}

@media (min-width: 1000px) {
  .header {
    margin-left: var(--sidepanel-width);
  }
}

.head {
  position: relative;
  text-align: center;
  height: 50px;
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  text-align: center;
}

@media (min-width: 800px) {
  .title {

  }
}

.link {
  text-align: center;
}

@media (min-width: 800px) {
  .link {

  }
}

.reschedule {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--pri-02);
  height: 30px;
  padding: 0 25px;
  border-radius: 20px;
}

.reschedule:hover {
  text-decoration: underline;
}

.btn {
  position: fixed;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  padding: 5px 30px;
  color: var(--pri-01);
  font-family: var(--font-semibold);
  transition: all 0.2s ease;
}

.btn-save,
.btn-send {
  composes: btn;
  width: 100px;
  height: auto;
}

.btn-confirm {
  composes: btn;
  height: auto;
}

.calendar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.col {
  width: 70px;
  height: 70px;
  text-align: center;
  margin: 5px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.device {
  position: relative;
  z-index: 34;
}

.col.device {
  justify-content: space-evenly;
}

.device .day,
.device .num {
  color: var(--gray-d);
  letter-spacing: -0.3px;
}

.device .num {
  font-size: 15px;
  font-family: var(--font-reg);
}

.device .day {
  font-family: var(--font-reg);
  font-size: 11px;
}

.today {
  composes: col;
  background-color: var(--pri-02);
  color: var(--pri-01);
  border-radius: 50%;
}

.day {
  font-family: var(--font-semibold);
  font-size: 18px;
}

.num {
  font-family: var(--font-semibold);
  font-size: 22px;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .15);
}

.eventOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.busy,
.available {
  cursor: pointer;
}

.is-loading {
  opacity: 0.3;
}

.panel {
  display: none;
}

@media 1000 {
  .panel {
    display: block;
    margin: 50px 0 0 0;
    width: var(--sidepanel-width);
  }
}

.picker {
  margin: 0px 20px 0 10px;
}

.timezone {
  color: var(--gray-d);
  position: absolute;
  bottom: -100px;
  font-size: 14px;
  letter-spacing: -0.5px;
  width: 55px;
  text-align: center;
  z-index: 2;
}

.parent,
.parent-providing,
.parent-selecting {
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
}

.row {
  max-width: 1080px;
}

.content {
  display: flex;
}

.toolbar {
  margin-left: 10px;
}

@media (min-width: 800px) {
  .root {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .btn {
    position: absolute;
    bottom: -42px;
    left: 0;
    z-index: 1;
  }

  .btn:hover:not(:disabled) {
    border: 1px solid var(--sec-03);
    color: var(--pri-01);
    background-color: var(--sec-03);
  }

  .btn:disabled:hover {
    cursor: not-allowed;
  }

  .wrap {
    width: 1080px;
  }

  .parent {
    height: calc(100vh - 130px);
  }

  .parent-providing,
  .parent-selecting {
    height: calc(100vh - 180px);
  }
}

/* https://stripesgenerator.com */
.moratorium {
  --bg-color: #f9f9f9;      /* 9 */
  --stripe-color: #282828;  /* 6 */

  background-image: linear-gradient(
    45deg,
    var(--bg-color)     30%,
    var(--stripe-color) 30%,
    var(--stripe-color) 50%,
    var(--bg-color)     50%,
    var(--bg-color)     80%,
    var(--stripe-color) 80%,
    var(--stripe-color) 100%
  ) !important;

  background-size: 21.21px 21.21px !important;
  background-attachment: fixed !important;
}

.tz {
  position: fixed;
  top: 145px;
  width: 55px;
  text-align: center;
  z-index: 2;
  color: var(--gray-d);
  font-size: 14px;
  letter-spacing: -0.5px;
}